import { Button, Grid, Typography, useTheme } from "@mui/material";
import CELogo from "../../assets/images/calgwinedulabs.jpeg";
import CEBackground from "../../assets/images/ce_background.png";

const CalgwinEdulabsSection: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${CEBackground})`,
        boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.5)", // Add a dark overlay tint
        height: "70vh",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        overflow: "hidden",
        padding: theme.spacing(2),
        "@media (max-width: 600px)": {
          height: "80vh",
        },
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          animation: "bounceIn 1s",
          textAlign: "center",
          px: { xs: 0, sm: "2rem" },
          mb: { xs: 10, sm: 0 },
        }}
      >
        <img
          src={CELogo}
          alt="Calgwin Edulabs"
          style={{ borderRadius: "50%", maxWidth: "70%", height: "auto" }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          animation: "bounceIn 1s",
          px: { xs: 0, sm: "2rem" },
        }}
      >
        <Typography variant="h1">Calgwin EduLabs</Typography>
        <Typography variant="body1" gutterBottom flexWrap="wrap">
          Our Edutech division, providing quality education to all.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          component={"a"}
          href="https://instagram.com/calgwin.edulabs"
        >
          Visit Instagram Page
        </Button>
      </Grid>
    </Grid>
  );
};

export default CalgwinEdulabsSection;
