import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

interface LinkButtonProps {
  to: string;
  children: React.ReactNode;
  target?: string;
  iconButton?: boolean;
  color?: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  children,
  target,
  iconButton,
  color,
}) => {
  return (
    <Link to={to} target={target} style={{ textDecoration: "none" }}>
      {iconButton ? (
        <IconButton sx={{ color }}>{children}</IconButton>
      ) : (
        <Button variant="contained" sx={{ bgcolor: color }}>
          {children}
        </Button>
      )}
    </Link>
  );
};

export default LinkButton;
