import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  CardMedia,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { gameDetails } from "./games";
import LinkButton from "../Custom/LinkButton";
import ItchioModal from "./ItchioModal";

const GameCard: React.FC<gameDetails> = ({
  id,
  name,
  tagline,
  image,
  released,
  embedId,
}: gameDetails) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <ItchioModal open={open} handleClose={handleClose} embedId={embedId} />
      <Card
        sx={{
          width: {
            xs: 275,
            sm: 345,
          },
          bgcolor: "#35596A",
        }}
      >
        <CardMedia sx={{ height: 140 }} image={image} title={name} />
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            justifyContent={"space-between"}
          >
            {name}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Released: {released}
          </Typography>
          <Typography variant="body2">{tagline}</Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ bgcolor: "secondary.main", color: "primary.contrastText" }}
          >
            Download
          </Button>
          <LinkButton to={`/games/${id}`} color={theme.palette.secondary.main}>
            Learn More
          </LinkButton>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default GameCard;
