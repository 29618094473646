import { Box, Typography } from "@mui/material";
import heroVideo from "../../assets/videos/hero_video.mp4";

const HeroSection: React.FC = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "70vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <video
        src={heroVideo}
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Add a darker overlay with 50% opacity
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Typography variant="h1">W-15 Interactive</Typography>
        <Typography variant="subtitle1">
          Connecting people around the globe, through software.
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroSection;
