import React from "react";
import Description from "../components/About/Description";
import AboutRoadmapSlider from "../components/About/AboutRoadmapSlider";
import { Typography } from "@mui/material";

const About: React.FC = () => {
  return (
    <React.Fragment>
      <Typography
        variant="h1"
        sx={{ mt: 2, mb: 5, textAlign: "center" }}
        gutterBottom
      >
        About Us
      </Typography>
      <Description />
      <AboutRoadmapSlider />
    </React.Fragment>
  );
};

export default About;
