import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Copyright: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Typography variant="body2" color="textPrimary">
      © {currentYear} W-15 Interactive. All rights reserved. |{" "}
      <Link
        to="/about"
        style={{
          color: "inherit",
          textDecoration: "underline",
        }}
      >
        About Us
      </Link>
    </Typography>
  );
};

export default Copyright;
