import { Button, Grid, Typography, useTheme } from "@mui/material";

import SoftwareSvg from "../../assets/images/software.svg";
import softwareBackground from "../../assets/images/code.jpg";
import LinkButton from "../Custom/LinkButton";

const SoftwareSection: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${softwareBackground})`,
        boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.7)", // Add a dark overlay tint
        height: "70vh",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        overflow: "hidden",
        padding: theme.spacing(2),

        "@media (max-width: 600px)": {
          height: "80vh",
        },
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          animation: "fadeInLeft 1s",
          textAlign: { xs: "center" },
          px: { xs: 0, sm: "2rem" },
        }}
      >
        <img src={SoftwareSvg} alt="Software" />
      </Grid>

      <Grid item xs={12} sm={6} sx={{ animation: "fadeInRight 1s" }}>
        <Typography variant="h1">Software</Typography>
        <Typography variant="body1" gutterBottom>
          We're yet to release our software products. Stay tuned!
        </Typography>
        <LinkButton to="/software" color="secondary.main">
          See Software
        </LinkButton>
      </Grid>
    </Grid>
  );
};

export default SoftwareSection;
