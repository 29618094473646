import { Box, CssBaseline, Divider, Toolbar } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import Navbar from "./components/Layout/Navbar/Navbar";
import Footer from "./components/Layout/Footer/Footer";
import { ScrollToTopComponent } from "./components/Layout/scrollToTop";

import "./index.css";

const App: React.FC = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar />
      <ScrollToTopComponent />
      <Box component="main" className="app">
        <Toolbar />
        <Outlet />
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default App;
