import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import theme from "./themes/themes";
import { ThemeProvider } from "@mui/material";

import App from "./App";
import Home from "./pages/Home";
import About from "./pages/About";
import Games from "./pages/Games";
import Software from "./pages/Software";

import SpecificGame from "./components/SpecificGames/SpecificGame";

import "./index.css";
import "animate.css";
import "@fontsource/roboto-slab";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="games" element={<Games />} />
          <Route path="games/:gameId" element={<SpecificGame />} />
          <Route path="software" element={<Software />} />
        </Route>
      </Routes>
    </Router>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
