import cogLogo from "./components/cog/gamelogo.png";
import cogBackground from "./components/cog/gamebackground.png";
import cotrLogo from "./components/cotr/gamelogo.png";
import cotrBackground from "./components/cotr/gamebackground.png";
import pfgLogo from "./components/pfg/gamelogo.png";
import pfgBackground from "./components/pfg/gamebackground.png";
import cipLogo from "./components/cip/gamelogo.png";
import cipBackground from "./components/cip/gamebackground.png";
import deserterBackground from "./components/deserter/gamebackground.png";

export type specificGame = {
    id: string;
    name: string;
    tagline?: string;
    description?: string;
    logo?: string;
    background: string;
    backgroundColor: string;
    images: string[];
    url: string;
    details: {
        genre: string;
        platforms: string[];
        releaseDate: string;
        playTime: string;
    }
};

export const specificGamesList: specificGame[] = [
    {
        id: "calgwin-origins-godi",
        name: "Calgwin Origins: Godi",
        tagline: "this isn't the same world, is it?",
        description: "You're Adiba, a military Sergeant of the GeoNissary Army, where you are living a monotonous life in a never-ending war between regional territories who are aiming to conquer the world. You are just a pawn in this chess game of world domination.\n\nUpon clearing a building of any enemies, you discover a mysterious glowing artifact that didn't seem to be of this world. When you proceed to touch it, suddenly you get caught in the midst of an epic earthquake, causing the building to crumble with you in it. No one to hear your call for help, no one to save you before this wretched building takes you down. You close your eyes and accept your fate.\n\nYou thought you were dead, instead you wake up from unconsciousness to find yourself in a the middle of a dense forest. It doesn't look like any forest you've been in before and besides, you weren't in a forest before to begin with. Wait, what's that sound? Wait, what are those creatures approaching me?! Wait, what is that projectile?! You've never seen them in this world!\n\nWell, that's because this isn't your world.\n\nWelcome to Calgwin, life's about to change drastically.",
        logo: cogLogo,
        background: cogBackground,
        backgroundColor: "rgba(130, 255, 130, 0.3)",
        images: [require("./components/cog/screenshots/screenshot1.png"), require("./components/cog/screenshots/screenshot2.png"), require("./components/cog/screenshots/screenshot3.png")],
        url: "https://w15interactive.itch.io/calgwin-origins-godi",
        details: {
            genre: "Action-Adventure",
            platforms: ["Windows", "Mac", "Linux"],
            releaseDate: "2023-12-27",
            playTime: "30m-1h"
        }
    }, 
    {
        id: "chase-of-the-ruby",
        name: "Chase of the Ruby",
        tagline: "Is it real...?",
        description: "The Skylands of Hershia, a world divided into two parts: Commoners, the ones who are trying to make a living to merely survive and Elites, the ones who are in possession of superpowers.\n\nFor Rosalie, a 23-year-old commoner residing in the poorest parts of Hershia, life was anything but fair. That is until she decides to take matters into her own hands and plots to steal the most powerful jewel of the kingdom, the ruby of Hershia.\n\nWhat are the consequences of a 23-year-old woman robbing the holy ruby of the skylands? Find out as she falls through an apparent eternal abyss.",
        logo: cotrLogo,
        background: cotrBackground,
        backgroundColor: "rgba(255, 130, 130, 0.3)",
        images: [
            require("./components/cotr/screenshots/screenshot1.jpg"),
            require("./components/cotr/screenshots/screenshot2.jpg"),
            require("./components/cotr/screenshots/screenshot3.jpg"),
        ],
        url: "https://w15interactive.itch.io/chase-of-the-ruby",
        details: {
            genre: "Action-Adventure",
            platforms: ["Windows"],
            releaseDate: "2022-02-27",
            playTime: "30m-1h"
        }
    },
    {
        id: "pursuit-for-gaia",
        name: "Pursuit for Gaia",
        tagline: "May the Earth be with You",
        description: "You start as an Intern in Ambassadors of Earth, a non-governmental environmentalist organisation. Your job is to limit and greatly reduce the increasing pollution that is harming our Earth, and raise awareness for it. You travel to cities and lands where you battle the pollution giants. With the help of your teammates and peers, you go on the journey of saving our Earth!",
        logo: pfgLogo,
        background: pfgBackground,
        backgroundColor: "rgba(110, 235, 150, 0.3)",
        images: [
            require("./components/pfg/screenshots/screenshot1.jpg"),
            require("./components/pfg/screenshots/screenshot2.jpg"),
            require("./components/pfg/screenshots/screenshot3.png"),
            require("./components/pfg/screenshots/screenshot4.png"),
        ],
        url: "https://w15interactive.itch.io/pursuit-for-gaia",
        details: {
            genre: "Adventure",
            platforms: ["Windows", "Mac"],
            releaseDate: "2021-08-31",
            playTime: "1h+"
        }
    },
    {
        id: "christmas-in-peril",
        name: "Christmas in Peril",
        tagline: "Naughty, or Nice?",
        description: "It's the day before Christmas, You (as either Aster and Asteria) are an orphan living with your relatives. You were onto your usual bad mischief,  when Elvis, Santa's right-hand Elf had jumped down to his/her room.\n\nElvis tells you that most kids like you are onto no good. These kids are giving Mr. Nightmare  more power, and he is on his way to shut down Christmas, forever. Christmas is on the brink of collapsing, and Elvis has been asking all the \"Naughty Kids\" to start doing good deeds to repent for their mischievousness if they want to save Christmas.\n\nNone of the kids bothered to listen to Elvis the Elf. But your parents loved Christmas, and you wanted to save Christmas in to keep the family traditions. So, along with Elvis the Elf, You are set to save Christmas and overcome the evil of Mr. Nightmare.",
        logo: cipLogo,
        background: cipBackground,
        backgroundColor: "rgba(130, 110, 255, 0.3)",
        images: [
            require("./components/cip/screenshots/screenshot1.jpg"),
            require("./components/cip/screenshots/screenshot2.jpg"),
            require("./components/cip/screenshots/screenshot3.jpg"),
        ],
        url: "https://w15interactive.itch.io/christmas-in-peril",
        details: {
            genre: "Adventure",
            platforms: ["Windows", "Mac", "Linux"],
            releaseDate: "2020-12-20",
            playTime: "30m-1h"
        }
    },
    {
        id: "deserter",
        name: "Deserter",
        tagline: "For we must restore peace.",
        description: "In the middle of the war between the Kingdom of Tialar and Lipzern, General Stone turns rogue on the city and Kulvan, the captain. Kulvan with his comrades, Zoren and Jidon, try to take the Kingdom back from the Evil General Malik to protect it's people!\n\n(This is a Collaboration between W-15 Interactive, VIGA Manufacture and Vincent Chu of Fantales Interactive for the Indie Game Maker Contest 2018! This game had been created in a month, so there might be visible bugs!)",

        background: deserterBackground,
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        images: [
            require("./components/deserter/screenshots/screenshot1.gif"),
            require("./components/deserter/screenshots/screenshot2.gif"),
            require("./components/deserter/screenshots/screenshot3.gif"),
        ],
        url: "https://w15interactive.itch.io/deserter",
        details: {
            genre: "Action-Adventure",
            platforms: ["Windows", "Mac", "Linux"],
            releaseDate: "2018-12-06",
            playTime: "1h-2h"
        }
    }

]