import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0C090D",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#643A71",
            contrastText: "#ffffff",
        },
        background: {
            default: "#ffffff",
            paper: "#090915",
        },
        text: {
            primary: "#ffffff",
            secondary: "#000000",
        },
    },
    typography: {
        fontFamily: "Roboto Slab, sans-serif",
        h1: {  
            "@media (max-width: 280px)": { // Add responsive styles for smaller screens
                fontSize: "1.6rem",
            },
        
            "@media (min-width: 281px) and (max-width: 600px)": { // Add responsive styles for smaller screens
                fontSize: "2.3rem",
            },

            "@media (min-width: 601px)": { // Add responsive styles for smaller screens
                fontSize: "4.5rem",
            },
            
            fontWeight: 700,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
        },
        body1: {
            "@media (max-width: 600px)": { // Add responsive styles for smaller screens
                fontSize: "0.8rem",
            },
            fontSize: "1.3rem",
        },
        body2: {
            fontSize: "1rem",
        },
        subtitle1: {
            "@media (max-width: 600px)": { // Add responsive styles for smaller screens
                fontSize: "0.8rem",
            },
            fontSize: "1.4rem",
        },
        subtitle2: {
            color: "lightgray",
        },
    },  
    
});

export default theme;
