import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Software: React.FC = () => {
  return (
    <Container maxWidth={"md"}>
      <Typography
        variant="h1"
        sx={{ mt: 2, mb: 5, textAlign: "center" }}
        gutterBottom
      >
        Software
      </Typography>
      <Box>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", height: "20vh" }}
          gutterBottom
        >
          We currently do not have any software available for download. However,
          we are actively working on several exciting projects. Stay tuned for
          updates!
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", height: "50vh" }}
          gutterBottom
        >
          In the meantime, check out our{" "}
          <Link
            style={{
              color: "inherit",
              textDecoration: "underline",
            }}
            to={"/games"}
          >
            games!
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Software;
