import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import { specificGame } from "../specificGames";
import GameDetails from "./GameDetails";
import GameScreenshots from "./GameScreenshots";

type GameDescriptionProps = {
  game: specificGame;
};

const GameDescription: React.FC<GameDescriptionProps> = ({ game }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start", // modified vertical alignment
          alignItems: "center",
          margin: "auto",
          width: "100%",
        }}
      >
        <GameDetails game={game} />
      </Box>

      <Grid
        container
        sx={{
          backgroundColor: game.backgroundColor,
          mt: 5,
          p: { xs: 0, sm: 3 },
        }}
        spacing={5}
      >
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", // modified vertical alignment
            alignItems: "start",
            textAlign: "left",
            mx: "auto",
            maxWidth: { xs: "75%" },
          }}
        >
          <Typography variant="h3" gutterBottom sx={{ mb: 6 }}>
            Synopsis
          </Typography>
          <Typography variant="body1" sx={{}} gutterBottom>
            {game.description?.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", // modified vertical alignment
            alignItems: "start",
            textAlign: "left",
            mx: "auto",
            maxWidth: { xs: "75%" },
          }}
        >
          <Typography variant="h3">Screenshots</Typography>
          <GameScreenshots screenshots={game.images} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GameDescription;
