import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { roadmapDetailsList } from "./RoadmapDetails";
import { Box, Typography } from "@mui/material";

const AboutRoadmapSlider: React.FC = () => {
  const colors = {
    pastelBlue: "rgb(100, 131, 185)",
    orange: "rgb(255, 150, 90)",
    purple: "rgb(185, 110, 141)",
  };

  return (
    <React.Fragment>
      <Box sx={{ my: 10, px: 5 }}>
        <VerticalTimeline>
          {roadmapDetailsList.map((item, index) => (
            <VerticalTimelineElement
              key={item.id}
              className="vertical-timeline-element--work"
              contentStyle={{
                background:
                  index % 3 === 0
                    ? colors.pastelBlue
                    : index % 3 === 1
                    ? colors.orange
                    : colors.purple,
                color: "#fff",
                boxShadow: "black 3px 3px 6px",
              }}
              contentArrowStyle={{
                borderRight: `7px solid ${
                  index % 3 === 0
                    ? colors.pastelBlue
                    : index % 3 === 1
                    ? colors.orange
                    : colors.purple
                }`,
              }}
              date={item.date}
              iconStyle={{
                background:
                  index % 3 === 0
                    ? colors.pastelBlue
                    : index % 3 === 1
                    ? colors.orange
                    : colors.purple,
                color: "#fff",
                top: "10px",
              }}
              icon={<FontAwesomeIcon icon={item.icon} />}
            >
              <Typography
                className="vertical-timeline-element-title"
                variant="h5"
              >
                {item.title}
              </Typography>
              <Typography
                className="vertical-timeline-element-subtitle"
                variant="h6"
              >
                {item.software}
              </Typography>
              <Typography variant="body1">{item.description}</Typography>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </Box>
    </React.Fragment>
  );
};

export default AboutRoadmapSlider;
