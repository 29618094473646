import React from "react";

type GamePageBackgroundProps = {
  image: string;
};

const GamePageBackground: React.FC<GamePageBackgroundProps> = ({ image }) => {
  return (
    <React.Fragment>
      <img
        src={image}
        style={{
          objectFit: "cover",
          top: 0,
          left: 0,
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
    </React.Fragment>
  );
};

export default GamePageBackground;
