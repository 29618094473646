import { Box, Grid } from "@mui/material";
import { games } from "./games";
import GameCard from "./GameCard";

const GridLayout: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mb: 5,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          width: { lg: "50%" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {games.map((game) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={game.id}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <GameCard {...game} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GridLayout;
