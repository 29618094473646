import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  useTheme,
} from "@mui/material";

import logo from "../../../assets/images/logo.png";
import "../layout.css";
import { Link, NavLink } from "react-router-dom";
import MobileNav from "./components/MobileNav";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const navItems = [
  { key: "about", name: "About", to: "/about" },
  { key: "games", name: "Games", to: "/games" },
  { key: "software", name: "Software", to: "/software" },
  { key: "blog", name: "Blog", to: "https://w15interactive.home.blog" },
];

const Navbar: React.FC = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <NavLink to={"/"} style={{ textDecoration: "none", color: "inherit" }}>
        <Typography variant="h6" sx={{ my: 2, textAlign: "center" }}>
          W-15 Interactive
        </Typography>
      </NavLink>
      <Divider variant="middle" sx={{ bgcolor: "white" }} />
      <List>
        {navItems.map((item: { key: string; name: string; to: string }) => (
          <ListItem key={item.key} disablePadding>
            <Link
              to={item.to}
              style={{
                textDecoration: "none",
                color: theme.palette.text.primary,
                textAlign: "center",
              }}
            >
              <ListItemButton>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" className="background">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              alignItems: "center", // Add this line
            }}
          >
            <Link to={"/"}>
              <Button sx={{ color: theme.palette.text.primary }}>
                <img src={logo} alt="Logo" height={"50vh"} />
                <span style={{ marginLeft: "0.5rem" }}>W-15 Interactive</span>
              </Button>
            </Link>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Link key={item.key} to={item.to}>
                <Button key={item.name} sx={{ color: "#fff" }}>
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <>
        <MobileNav
          container={container}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          theme={theme}
          drawer={drawer}
        />
      </>
    </Box>
  );
};

export default Navbar;
