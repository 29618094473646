import { Modal, Box, Link, Button } from "@mui/material";
import Iframe from "react-iframe";

type ItchioModalProps = {
  open: boolean;
  embedId: string;
  handleClose: () => void;
};

const ItchioModal: React.FC<ItchioModalProps> = ({
  open,
  embedId,
  handleClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ padding: 3 }}>
        <Iframe
          url={`https://itch.io/embed/${embedId}`}
          width="100%"
          height="167"
          styles={{ borderRadius: "15px" }}
          allowFullScreen
        />
        <Link
          visibility={"hidden"}
          href="https://w15interactive.itch.io/chase-of-the-ruby"
        >
          Chase of the Ruby by W-15 Interactive
        </Link>
        <br />
        <Button
          variant="contained"
          size="large"
          onClick={handleClose}
          sx={{ bgcolor: "secondary.main", color: "primary.contrastText" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ItchioModal;
