import { Box, Button, Typography } from "@mui/material";
import { specificGame } from "../specificGames";
import GamePageBackground from "./GamePageBackground";

type GameHeroProps = {
  game: specificGame;
};

const GameHero: React.FC<GameHeroProps> = ({ game }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "70vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <GamePageBackground image={game.background} />
      <Box
        sx={{
          position: "absolute",
          textAlign: "center",
          zIndex: 3,
          top: 0,
          animation: "fadeInDown 1.5s",
          overflow: "hidden",
        }}
      >
        {game.logo && (
          <img
            src={game.logo}
            alt="logo"
            style={{
              width: "20rem",
            }}
          />
        )}
      </Box>
      <Box
        className="animate__animated animate__fadeInLeft"
        sx={{
          position: "absolute",
          textAlign: "center",
          zIndex: 3,
          top: "16rem",
          animationDelay: "1.5s",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            zIndex: 3,

            py: 1,
          }}
        >
          {game.name}
        </Typography>
        <Typography
          variant="subtitle1"
          className="animate__animated animate__fadeIn"
          sx={{
            zIndex: 3,
            animationDelay: "3s",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          <em>"{game.tagline}"</em>
        </Typography>
      </Box>
    </Box>
  );
};

export default GameHero;
